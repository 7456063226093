import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { PageLayout, PrimaryButton, RESET_PARAMS, Row, Table, Title, dynamicLink, selectQueryParams, useTranslation } from '@gk-devteam/apmc-core-web'

import { selectConstructionWorksCount, selectLoadingConstructionWorks, selectConstructionWorksData } from '../../../selectors'
import { FETCH_CONSTRUCTION_WORKS, RESET_CONSTRUCTION_WORKS } from '../../../types'
import { yasueConstructionWorksSearchSchema } from '../../../validation'
import { fetchConstructionWorks } from '../../../services'

import DownloadCSVButton from '../../../components/Misc/DownloadCSVButton'
import ConstructionWorksSearchBar from './ConstructionWorksSearchBar'
import DeleteModal from './DeleteModal'
import { IMPORT_TYPES } from '../../../constants'

const ConstructionWorksPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'yasue'])
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteContructionWorkID, setDeleteConstructionWorkID] = useState(null)

  const fetchData = useCallback(
    () => {
      dispatch({ type: FETCH_CONSTRUCTION_WORKS, query })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_CONSTRUCTION_WORKS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const navigateToAdd = () => {
    navigate('./add/')
  }

  const navigateToDetails = (id, row, e) => {
    const url = `/yasue/construction_works/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const navigateToCSV = () => {
    navigate(`/import?type=${IMPORT_TYPES.YASUE_CONTRUCTION_WORK}`)
  }

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/yasue/construction_works/add', { state: { id, copy: true } })
    },
    [navigate]
  )

  const _closeDeleteModal = () => {
    setDeleteModalVisible(false)
    setDeleteConstructionWorkID(null)
  }

  const _deleteConstructionWork = (id, close, row) => {
    setDeleteModalVisible(true)
    setDeleteConstructionWorkID(id)
    close()
  }

  const breadcrumbs = [
    {
      label: 'navigation:yasue.construction_work_list',
      link: '/yasue/construction_works?sort_by=contract_date&sort_order=desc'
    }
  ]

  const constructionWorksColumns = [
    // {
    //   title: t('yasue:construction.construction_work_id'),
    //   dataIndex: 'id',
    //   key: 'id',
    //   cellTitle: true,
    //   sortable: true
    // },
    // {
    //   title: t('contracts:contract_id'),
    //   dataIndex: 'contract_id',
    //   key: 'contract_id',
    //   cellTitle: false,
    //   sortable: true
    // },
    {
      title: t('yasue:construction.work_number'),
      dataIndex: 'work_number',
      key: 'work_number',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('yasue:construction.title'),
      dataIndex: 'title',
      key: 'title',
      cellTitle: false,
      sortable: false
    },
    {
      title: t('yasue:construction.area'),
      dataIndex: 'area',
      key: 'area',
      cellTitle: false,
      sortable: false
    },
    {
      title: t('yasue:construction.contract_date'),
      dataIndex: 'contract_date',
      key: 'contract_date',
      cellTitle: false,
      sortable: true
    },
    {
      title: t('yasue:construction.delivery_date'),
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      cellTitle: false,
      sortable: true
    },
    {
      title: t('yasue:construction.completion_date'),
      dataIndex: 'completion_date',
      key: 'completion_date',
      cellTitle: false,
      sortable: true
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          },
          shouldRender: 'contracts.functions.add'
        },
        {
          label: t('delete'),
          dangerColor: true,
          onClick: (id, close, row) => {
            _deleteConstructionWork(id, close, row)
            close()
          }
        }
      ]
    }
  ]

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      sidebar={
        () => <ConstructionWorksSearchBar />
      }
    >
      <DeleteModal
        visible={deleteModalVisible}
        onCancel={_closeDeleteModal}
        constructionWorkID={deleteContructionWorkID}
      />
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:yasue.construction_work_list"/>
        <div>
          <DownloadCSVButton
            schemaBuilder={yasueConstructionWorksSearchSchema}
            fetchCSV={fetchConstructionWorks}
            filename="construction_work.csv"
          />
          <PrimaryButton
            label="add_csv"
            handleClick={navigateToCSV}
            withMargin
          />
          <PrimaryButton
            label="yasue:construction.construction_work_add"
            handleClick={navigateToAdd}
            reverse
            withMargin
          />
        </div>
      </Row>
      <Table
        fullHeight
        columns={constructionWorksColumns}
        rowKey="id"
        loadingSelector={selectLoadingConstructionWorks}
        resultsCountSelector={selectConstructionWorksCount}
        dataSelector={selectConstructionWorksData}
        onRowClick={navigateToDetails}
      />
    </PageLayout>
  )
}

export default ConstructionWorksPage

ConstructionWorksPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
ConstructionWorksPage.defaultProps = {

}
